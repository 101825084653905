import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MailService {

  private url = 'https://juanloarregla.com/mail.php';
  private urlCotizaPorActividad = 'https://juanloarregla.com/mailCotizaPorActividad.php';
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  constructor(private http: HttpClient) { }

  send(data: FormData): Observable<any> {
    return this.http.post<any>(this.url, data);
  }

  sendArray(data: any[], unEmail, unServicio, unNombre, unTelefono, unaFecha, unValorTotal, unCiudad): Observable<any> {
    let json = JSON.stringify({ email: unEmail, servicio: unServicio, nombre: unNombre, telefono: unTelefono, fecha: unaFecha, cotizacion: unValorTotal, ciudad: unCiudad, lista: data });
    return this.http.post<any>(this.urlCotizaPorActividad, json);
  }
}
