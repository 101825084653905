import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-habeas-data',
  templateUrl: './habeas-data.component.html',
  styleUrls: ['./habeas-data.component.scss']
})
export class HabeasDataComponent {

  constructor(
    public dialogRef: MatDialogRef<HabeasDataComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}


}
