import { Actividad } from './../../models/actividad';
import { ActividadesService } from './../actividades.service';
import { Component, OnInit, Input } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-actividades',
  templateUrl: './actividades.component.html',
  styleUrls: ['./actividades.component.scss']
})
export class ActividadesComponent implements OnInit {

  actividades: Actividad[];

  constructor(private actividadesService: ActividadesService, private meta: Meta, private titleService: Title) {
    meta.addTag({name: `description`, content: `Contamos con una variada oferta de actividades de instalación, `
    || `mantenimiento y reparaciones para el hogar y empresas. Conoce nuestros servicios. `});
    meta.addTag({name: `keywords`, content: `Cerrajeros a domiclio, remodelaciones para el hogar, plomeria a domicilio`});
    // meta.addTag({name: `viewport`, content: `width=device-width, initial-scale=1`});
    meta.addTag({property: `og:title`, content: `Actividades de instalación y mantenimiento | Juan lo Arregla`});
    meta.addTag({property: `og:type`, content: `website`});
   }

  ngOnInit() {
    this.titleService.setTitle( 'Actividades de instalación y mantenimiento | Juan lo Arreglaa' );
    this.getActividades();
  }

  getActividades(): void {
    this.actividades = this.actividadesService.getActividades();
  }

}
