<div
  class="container row min-fullscreen d-flex d-flex align-items-center"
  *ngIf="actividad"
>
<div class="col-lg-6">
  <app-cotizador-jornada [tipoServicio]="actividad.titulo"></app-cotizador-jornada>
  <!-- <img
    class="img-fluid w-75 img-complemento"
    src="assets/img/actividades/{{ actividad.nombre }}.jpg"
  /> -->
</div>
  <div class="col-lg-6 txt-contenido">
    <h1 class="titulo-2">{{ actividad.titulo }}</h1>
    <div [innerHTML]="actividad.texto"></div>
  </div>
</div>
