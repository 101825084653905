<h1 class="titulo-2">{{ servicio.titulo }}</h1>
<div class="container row min-fullscreen d-flex d-flex align-items-center" *ngIf="servicio">
  <div class="col-lg-6 txt-contenido">
    <div [innerHTML]="servicio.texto"></div>

    <app-contacto *ngIf="servicio.id != 3" ></app-contacto>
    <app-inscripcion *ngIf="servicio.id == 3" ></app-inscripcion>

  </div>
  <div class="col-lg-6 text-center">
    <img
      class="img-fluid img-complemento"
      src="assets/img/servicios/{{ servicio.nombre }}.jpg"
      alt="{{ servicio.alt }}"
    />
  </div>
</div>
