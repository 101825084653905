<div class="text-center txt-contenido">
    <h1>Cotiza por actividad</h1>
</div>
<form [formGroup]="cotizacionForm" (ngSubmit)="validarActividadesCotizadas()" class="row">
  <div class="form-group col-sm-4">
    <label>Ciudad</label>
    <select class="form-control" formControlName="ciudad">
      <option value="Bogota D.C.">Bogota D.C.</option>
      <option value="Villavicencio">Villavicencio</option>
  </select>
  </div>
  <div class="form-group col-sm-4">
    <label>Correo</label>
    <input type="text" formControlName="email" class="form-control"
      [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
    <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
      <div *ngIf="f.email.errors.required">Este campo es requerido</div>
      <div *ngIf="f.email.errors.email">
        el correo debe ser un correo valido
      </div>
    </div>
  </div>
  <div class="form-group col-sm-4">
    <label>Nombre</label>
    <input type="text" formControlName="nombre" class="form-control"
      [ngClass]="{ 'is-invalid': submitted && f.nombre.errors }" />
    <div *ngIf="submitted && f.nombre.errors" class="invalid-feedback">
      <div *ngIf="f.nombre.errors.required">Este campo es requerido</div>
    </div>
  </div>
  <div class="form-group col-sm-4">
    <label>Teléfono</label>
    <input type="number" class="form-control" formControlName="telefono" />
  </div>
  <div class="form-group col-sm-4">
    <label for="exampleFormControlTextarea1">fecha</label>
    <input type="date" formControlName="fecha" class="form-control"
      [ngClass]="{ 'is-invalid': submitted && f.fecha.errors }" />
    <div *ngIf="submitted && f.fecha.errors" class="invalid-feedback">
      <div *ngIf="f.fecha.errors.required">
        Debes seleccionar una jornada
      </div>
    </div>
  </div>
  <div class="form-check terminos">
    <input type="checkbox" formControlName="habeas" class="form-check-input"
      [ngClass]="{ 'is-invalid': submitted && f.habeas.errors }" />
    <label class="form-check-label" (click)=verHabeas()>
      Acepto <span> términos y condiciones</span>
    </label>
    <div *ngIf="submitted && f.habeas.errors" class="invalid-feedback">
      <div *ngIf="f.habeas.errors.required">debe aceptar los términos</div>
    </div>
  </div>
  <input type="hidden" formControlName="servicio" class="form-control" />
  <div class="w-100 d-none d-md-block"></div>
  <div class="form-group col-sm-4">
    <button class="btn btn-danger">Reservar</button>
  </div>
</form>
<!-- Tabla resuman de productos comprados -->
<table class="table table-sm table-responsive" *ngIf="actividadesCotizadas.length > 0">
  <thead>
    <tr>
      <th scope="col">Resumen:</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let tarifa of actividadesCotizadas">
      <td>{{tarifa.subactividad}} ({{tarifa.valor | currency:'COP': 'symbol-narrow' : '3.0'}}) X {{tarifa.cantidad}}</td>
    </tr>
  </tbody>
</table>

<div class="col-lg-6 txt-contenido">
  <h2 class="titulo-2">Actividades</h2>
</div>
<div class="" style="margin-bottom: 4rem">
  <mat-form-field style="padding: 1rem 2rem; ">
    <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Buscar..">
  </mat-form-field>
  <table mat-table [dataSource]="dataSource" matSort style="width: 100%">
    <!-- <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Codigo </th>
      <td mat-cell *matCellDef="let element"> {{element.id}} </td>
    </ng-container> -->

    <!-- <ng-container matColumnDef="actividad">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Actividad </th>
      <td mat-cell *matCellDef="let element"> {{element.actividad}} </td>
    </ng-container> -->

    <ng-container matColumnDef="subactividad">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Subactividad </th>
      <td mat-cell *matCellDef="let element"> {{element.subactividad}} </td>
    </ng-container>

    <ng-container matColumnDef="unidad">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Unidad </th>
      <td mat-cell *matCellDef="let element"> {{element.unidad}} </td>
    </ng-container>

    <ng-container matColumnDef="valor">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Valor </th>
      <td mat-cell *matCellDef="let element"> {{element.valor | currency:'COP': 'symbol-narrow' : '3.0'}} </td>
    </ng-container>

    <ng-container matColumnDef="acciones">
      <th mat-header-cell *matHeaderCellDef> Acciones </th>
      <td mat-cell *matCellDef="let element" class="btn-group" role="group">
        <button type="button" class="btn btn-danger btn-secondary" (click)="restarActividad(element)">-</button>
        <input type="button" class="btn btn-secondary cantidad" disabled value={{element.cantidad}} />
        <button type="button" class="btn btn-danger btn-secondary" (click)="sumarActividad(element)">+</button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
</div>
