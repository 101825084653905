<div class="row">
  <div class="line col-sm-3"></div>
  <h3 class="col-sm-6 text-center titulo-cotizador">Cotiza aquí!</h3>
  <div class="line col-sm-3"></div>
</div>
<form [formGroup]="cotizacionForm" (ngSubmit)="onSubmit()">
  <div class="form-group ">
    <label>Ciudad</label>
    <select class="form-control" formControlName="ciudad">
      <option value="Bogota D.C.">Bogota D.C.</option>
      <option value="Villavicencio">Villavicencio</option>
  </select>
  </div>
  <div class="form-group ">
    <label>Correo</label>
    <input type="text" formControlName="email" class="form-control"
      [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
    <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
      <div *ngIf="f.email.errors.required">Este campo es requerido</div>
      <div *ngIf="f.email.errors.email">
        el correo debe ser un correo valido
      </div>
    </div>
  </div>
  <div class="form-group ">
    <label>Nombre</label>
    <input type="text" formControlName="nombre" class="form-control"
      [ngClass]="{ 'is-invalid': submitted && f.nombre.errors }" />
    <div *ngIf="submitted && f.nombre.errors" class="invalid-feedback">
      <div *ngIf="f.nombre.errors.required">Este campo es requerido</div>
    </div>
  </div>
  <div class="form-group ">
    <label>Teléfono</label>
    <input type="number" class="form-control" formControlName="telefono" />
  </div>
  <div class="form-group ">
    <label>Número de días</label>
    <input type="number" formControlName="cantidad" class="form-control"
      [ngClass]="{ 'is-invalid': submitted && f.cantidad.errors }" />
    <div *ngIf="submitted && f.cantidad.errors" class="invalid-feedback">
      <div *ngIf="f.cantidad.errors.required">
        Debes seleccionar una cantidad
      </div>
    </div>
  </div>
  <div class="form-group ">
    <label>Descripción</label>
    <textarea formControlName="descripcion" class="form-control" rows="3"
      [ngClass]="{ 'is-invalid': submitted && f.descripcion.errors }"></textarea>
    <div *ngIf="submitted && f.descripcion.errors" class="invalid-feedback">
      <div *ngIf="f.descripcion.errors.required">
        Debes ingresar un detalle de la cotización para poder enviarla
      </div>
    </div>
  </div>
  <div class="form-check terminos">
    <input type="checkbox" formControlName="habeas" class="form-check-input"
      [ngClass]="{ 'is-invalid': submitted && f.habeas.errors }" />
    <label class="form-check-label" (click)=verHabeas()>
      Acepto <span> términos y condiciones</span>
    </label>
    <div *ngIf="submitted && f.habeas.errors" class="invalid-feedback">
      <div *ngIf="f.habeas.errors.required">debe aceptar los términos</div>
    </div>
  </div>
  <input type="hidden" formControlName="servicio" class="form-control" />
  <div class="w-100 d-none d-md-block"></div>
  <div class="form-group ">
    <button class="btn btn-danger">Reservar</button>
  </div>
</form>
