import { Servicio } from './servicio';

export const SERVICIOS: Servicio[] = [
  {
    id: 1,
    nombre: 'COTIZA_ACTIVIDAD',
    titulo: 'Cotiza por actividad',
    texto: '',
    textoCorto: 'Cuando el cliente requiera un presupuesto para realizar arreglos o instalaciones, puede cotizar por actividad.',
    estado: 'string',
    urlImg: 'string',
    alt: 'Plomeros a domiclio Bogotá'
  },
  {
    id: 2,
    nombre: 'COTIZA_DIA',
    titulo: 'Cotiza por día',
    texto: '<p>Cuenta con un kit de herramientas manuales más una herramienta electro-manual para el desarrollo de las actividades.</p><h3>Horario:</h3><p>Lunes a viernes 7:30 am – 5:30 pm</br>Valor: $ 120.000</p><h3>Valores adicionales:</h3><p>Si el cliente requiere realizar actividades en altura como mantenimiento de cubiertas, instalación de avisos entre otros. ( trabajos en alturas de más de 4 m).<br>Valor: $ 40.000 </p><p>Si por decisión del cliente el trabajador debe extender el horario establecido, se cobrar un valor adicional diario de la siguiente manera:</p><ul><li>Horas extra: $ 15.000</li><li>Horario nocturno: $ 75.000</li><li>Sábados, Domingos y festivos: $ 60.000</li><li>Horario nocturno Domingos y festivos: $ 95.000</li><li>Herramientas electro-manuales: $10.000</li></ul><p><b>SUJETO A CONDICIONES Y RESTRICCIONES.</b></p>',
    textoCorto: 'En esta modalidad, el cliente contrata el trabajador por día, el cual estará a disposición para realizar actividades de instalaciones, arreglos básicos de electricidad, instalación de grifería hidráulica, mudanzas, instalaciones decorativas entres otros.',
    estado: 'string',
    urlImg: 'string',
    alt: 'Plomeros en Bogotá'
  },
  {
    id: 3,
    nombre: 'INSCRIPCION',
    titulo: 'Mano derecha',
    texto: '<h3>Que es:</h3><p>Mano derecha es un nuevo formato que le da la facilidad a los clientes  de estar inscritos a nuestro servicio, con el fin  de contar con todo nuestro portafolio de actividades bajo un mismo precio.</p><p>Con este servicio los usuarios tienen la oportunidad de hacer su calendario de actividades o llamar a solicitar cuando lo requieran.</p>',
    textoCorto: 'Pensando en nuestros clientes, Juan lo arregla ofrece el servicio de inscripción con el objetivo de brindar todos nuestros servicios  por un  valor de $ 25.000.',
    estado: 'string',
    urlImg: 'string',
    alt: 'Juan lo Arregla Mano Derecha'
  },
  {
    id: 4,
    nombre: 'COTIZACION_ESPECIAL',
    titulo: 'Cotización personalizada',
    texto: '',
    textoCorto: 'En esta modalidad el cliente tiene la posibilidad de contar todos los problemas e inquietudes sobre el trabajo a realizar. Para esto se pueden adjuntar fotos y entregar documentos que sirvan para enviar el presupuesto.',
    estado: 'string',
    urlImg: 'string',
    alt: 'Instalaciones hidraulicas'
  },
];
