<!-- Footer -->
<footer class="page-footer">
  <div class="footer-line"></div>
  <div class="row">
      <div class="col-sm-4 text-left">
          (314) 443 4298 - (031) 338 3229
      </div>
      <div class="col-sm-4 text-center">
        Carrera 28a #53a -66
      </div>
      <div class="col-sm-4 text-right">
        Bogotá D.C y <br>
        Villavicencio, Colombia
      </div>

  </div>

</footer>
<!-- Footer -->
