<div class="container row min-fullscreen d-flex d-flex align-items-center" *ngIf="actividad">

  <div *ngIf="actividad.id === 8" class="col-lg-6">
    <app-cotizador-jornada [tipoServicio]="actividad.titulo"></app-cotizador-jornada>
  </div>
  <div class="col-lg-6 txt-contenido">
    <h1 class="titulo-2">{{ actividad.titulo }}</h1>
    <div [innerHTML]="actividad.texto"></div>
  </div>
  <div *ngIf="actividad.id !== 8" class="col-lg-6 text-center">
    <img class="img-fluid w-75 img-complemento" src="assets/img/actividades/{{ actividad.nombre }}.jpg" alt="{{ actividad.alt }}" />
    <!-- {{ actividad.nombre }} -->
  </div>
</div>
<!-- <app-botones-servicios *ngIf="actividad.id !== 8" ></app-botones-servicios> -->
<div class="text-center" *ngIf="actividad.id !== 8"><a class="btn btn-danger col-sm-2"
    routerLink="/servicio/cotiza_actividad">¡COTIZA AQUÍ!</a></div>
