import { MailService } from './../../formularios/mail.service';
import { ActividadesService } from './../actividades.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Actividad } from './../../models/actividad';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-limpieza',
  templateUrl: './limpieza.component.html',
  styleUrls: ['./limpieza.component.scss']
})
export class LimpiezaComponent implements OnInit {

  actividad: Actividad;
  cotizacionForm: FormGroup;
  submitted: boolean;

  constructor(private actividadesService: ActividadesService) { }

  ngOnInit() {
    this.getActividad();
  }

  getActividad(): void {
    const id = 9;
    this.actividad = this.actividadesService.getActividad(id);
  }

}
