<header>
  <div class="navbar">
    <a class="navbar-brand logo" routerLink="/"><img src="assets/img/logo.png" /></a>
    <nav class="nav justify-content-end">
      <a class="nav-link" routerLink="/nosotros">Nosotros</a>
      <a class="nav-link" routerLink="/actividades">Actividades</a>
      <div class="btn-group">
        <a class="nav-link" routerLink="/servicios" style="padding-right: 0px">Servicios</a>
        <a class="nav-link dropdown-toggle dropdown-toggle-split" id="navbarDropdown" role="button" data-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false" data-hover="dropdown" style="padding-left: 8px">
          <span class="sr-only">Toggle Dropdown</span>
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a routerLink="/servicio/{{ servicio.nombre.toLowerCase() }}" *ngFor="let servicio of servicios"
            class="dropdown-item">
            {{ servicio.titulo }}
          </a>
        </div>
      </div>
    </nav>
  </div>
</header>
