import { Meta, Title } from '@angular/platform-browser';
import { Component, OnInit, Input } from '@angular/core';
import { Servicio } from 'src/app/models/servicio';
import { ServiciosService } from '../servicios.service';

@Component({
  selector: 'app-cotiza-dias',
  templateUrl: './cotiza-dias.component.html',
  styleUrls: ['./cotiza-dias.component.scss']
})
export class CotizaDiasComponent implements OnInit {

  @Input()  servicio: Servicio;

  constructor(
    private serviciosService: ServiciosService,
    private meta: Meta, private titleService: Title) {
      meta.addTag({name: `description`, content: `Juan lo Arregla te ofrece la oportunidad de contar con profesionales en plomería, electricidad, jardinería e instaladores por día. Cotiza ya mismo.`});
      meta.addTag({name: `keywords`, content: `Cerrajeros a domiclio, remodelaciones para el hogar, plomeria a domicilio`});
      // meta.addTag({name: `viewport`, content: `width=device-width, initial-scale=1`});
      meta.addTag({property: `og:title`, content: `Plomeros, electricistas y pintores por día | Juan lo Arregla`});
      meta.addTag({property: `og:type`, content: `website`});

    }

  ngOnInit() {
    this.titleService.setTitle( 'Plomeros, electricistas y pintores por día | Juan lo Arregla' );
    this.getServicio();
  }

  getServicio() {
    const nombre = 'cotiza_dia';
    this.servicio = this.serviciosService.getServicioByNombre(nombre);
  }
}
