import { Component, OnInit } from '@angular/core';
import { Servicio } from 'src/app/models/servicio';
import { ServiciosService } from 'src/app/servicios/servicios.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  servicios: Servicio[];

  constructor(private serviciosServices: ServiciosService) { }

  ngOnInit() {
    this.getServicios();
  }

  getServicios(): void {
    this.servicios = this.serviciosServices.getServicios();
  }

}
