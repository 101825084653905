import { CotizaActividadComponent } from './formularios/cotiza-actividad/cotiza-actividad.component';
import { CotizaDiasComponent } from './servicios/cotiza-dias/cotiza-dias.component';
import { LimpiezaComponent } from './actividades/limpieza/limpieza.component';
import { PinturaComponent } from './actividades/pintura/pintura.component';
import { ServicioGeneralComponent } from './servicios/servicio-general/servicio-general.component';
import { ActividadGeneralComponent } from './actividades/actividad-general/actividad-general.component';
import { ServiciosComponent } from './servicios/servicios/servicios.component';
import { ActividadesComponent } from './actividades/actividades/actividades.component';
import { NosotrosComponent } from './general/nosotros/nosotros.component';
import { HomeComponent } from './general/home/home.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContactoComponent } from './formularios/contacto/contacto.component';

const routes: Routes = [
  {path: '', component: HomeComponent},
  {path: 'nosotros', component: NosotrosComponent},
  {path: 'actividades', component: ActividadesComponent},
  {path: 'actividad/pintura', component: PinturaComponent},
  {path: 'actividad/limpieza', component: LimpiezaComponent},
  {path: 'actividad/:nombre', component: ActividadGeneralComponent},
  {path: 'servicios', component: ServiciosComponent},
  {path: 'servicio/cotiza_dia', component: CotizaDiasComponent},
  {path: 'servicio/cotiza_actividad', component: CotizaActividadComponent},
  {path: 'servicio/:nombre', component: ServicioGeneralComponent},
  {path: 'contacto', component: ContactoComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
