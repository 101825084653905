<div class="container row min-fullscreen d-flex d-flex align-items-center" *ngIf="actividad">
  <div class="col-lg-6">
    <div class="row">
      <div class="line col-sm-3"></div>
      <h3 class="col-sm-6 text-center titulo-cotizador">Cotiza aquí!</h3>
      <div class="line col-sm-3"></div>
    </div>
    <form [formGroup]="cotizacionForm" (ngSubmit)="onSubmit()">
      <div class="form-group">
        <label>Correo</label>
        <input type="text" formControlName="email" class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
          <div *ngIf="f.email.errors.required">Este campo es requerido</div>
          <div *ngIf="f.email.errors.email">
            el correo debe ser un correo valido
          </div>
        </div>
      </div>
      <div class="form-group">
        <label>Nombre</label>
        <input type="text" formControlName="nombre" class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.nombre.errors }" />
        <div *ngIf="submitted && f.nombre.errors" class="invalid-feedback">
          <div *ngIf="f.nombre.errors.required">Este campo es requerido</div>
        </div>
      </div>
      <div class="form-group">
        <label>Teléfono</label>
        <input type="number" class="form-control" formControlName="telefono" />
      </div>
      <div class="form-group">
        <label>¿Que deseas pintar?</label>
        <select class="form-control" formControlName="tipo" [ngClass]="{ 'is-invalid': submitted && f.tipo.errors }">
          <option>mi casa</option>
          <option>una(s) pared(es)</option>
        </select>
        <div *ngIf="submitted && f.tipo.errors" class="invalid-feedback">
          <div *ngIf="f.tipo.errors.required">
            Debes seleccionar una
          </div>
        </div>
      </div>
      <div class="w-100 d-none d-md-block"></div>
      <div *ngIf="f.tipo.value === 'mi casa'">
        <div class="form-group">
          <label>¿cuantos metros cuadrados tiene tu casa?</label>
          <input type="number" formControlName="metros" class="form-control" />
        </div>
        <div class="form-group">
          <label>¿Tu casa está amoblada?</label>
          <select class="form-control" formControlName="amoblada">
            <option>Si</option>
            <option>No</option>
          </select>
        </div>
      </div>
      <div *ngIf="f.tipo.value === 'una(s) pared(es)'">
        <div class="form-group">
          <label>¿cuantas paredes son?</label>
          <input type="number" formControlName="cantidad" class="form-control" />
        </div>
        <div class="form-group">
          <label>¿cual es el promedio de metros cuadrados de las paredes?</label>
          <input type="number" formControlName="metros" class="form-control" />
        </div>
        <div class="form-group">
          <label>¿Son paredes de mas de 2,25 metros?</label>
          <select class="form-control" formControlName="altura">
            <option>Si</option>
            <option>No</option>
          </select>
        </div>
      </div>
      <div class="form-check terminos">
        <input type="checkbox" formControlName="habeas" class="form-check-input"
          [ngClass]="{ 'is-invalid': submitted && f.habeas.errors }" />
        <label class="form-check-label" (click)=verHabeas()>
          Acepto <span> términos y condiciones</span>
        </label>
        <div *ngIf="submitted && f.habeas.errors" class="invalid-feedback">
          <div *ngIf="f.habeas.errors.required">debe aceptar los términos</div>
        </div>
      </div>
      <input type="hidden" formControlName="servicio" class="form-control" />
      <div class="w-100 d-none d-md-block"></div>
      <div class="form-group">
        <button class="btn btn-danger">Reservar</button>
      </div>
    </form>
  </div>
  <div class="col-lg-6 txt-contenido">
    <h1 class="titulo-2">{{ actividad.titulo }}</h1>
    <div [innerHTML]="actividad.texto"></div>
  </div>
</div>
