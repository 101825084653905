import { ServiciosService } from './../servicios.service';
import { Servicio } from './../../models/servicio';
import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-servicios',
  templateUrl: './servicios.component.html',
  styleUrls: ['./servicios.component.scss']
})
export class ServiciosComponent implements OnInit {

  servicios: Servicio[];

  constructor(private serviciosServices: ServiciosService, private meta: Meta, private titleService: Title) {
    meta.addTag({name: `description`, content: `Cotiza con nosotros ya mismo servicios de instalación, plomería, electricistas, jardinería a domicilio en Bogotá. Servicio profesional garantizado.`});
    meta.addTag({name: `keywords`, content: `Cerrajeros a domiclio, remodelaciones para el hogar, plomeria a domicilio`});
    // meta.addTag({name: `viewport`, content: `width=device-width, initial-scale=1`});
    meta.addTag({property: `og:title`, content: `Servicios profesionales de instalación. Cotiza ya | Juan lo Arregla`});
    meta.addTag({property: `og:type`, content: `website`});

   }

  ngOnInit() {
    this.getServicios();
    this.titleService.setTitle( 'Servicios profesionales de instalación. Cotiza ya | Juan lo Arregla' );
  }

  getServicios(): void {
    this.servicios = this.serviciosServices.getServicios();
  }

}
