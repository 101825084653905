import { Meta, Title } from '@angular/platform-browser';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-inscripcion',
  templateUrl: './inscripcion.component.html',
  styleUrls: ['./inscripcion.component.scss']
})
export class InscripcionComponent implements OnInit {
  constructor(private meta: Meta, private titleService: Title) {
    meta.addTag({name: `description`, content: `Conoce nuestro plan Mano Derecha, con el cual podrás tener nuestros servicios profesionales de plomería, electricidad, jardinería y demás todo el año.`});
    meta.addTag({name: `keywords`, content: `Cerrajeros a domiclio, remodelaciones para el hogar, plomeria a domicilio`});
    // meta.addTag({name: `viewport`, content: `width=device-width, initial-scale=1`});
    meta.addTag({property: `og:title`, content: `Mano Derecha. Arreglos locativos todo el año | Juan lo Arregla`});
    meta.addTag({property: `og:type`, content: `website`});
  }

  ngOnInit() {
    this.titleService.setTitle( 'Mano Derecha. Arreglos locativos todo el año | Juan lo Arregla' );
  }

}
