
<div class="text-center txt-contenido">
    <h1>Conoce nuestros servicios</h1>
</div>
<div class="d-flex justify-content-center">
  <div class="row row-eq-height d-flex justify-content-around d-flex flex-wrap w-75">
    <div class="col-sm d-flex flex-column unServicio" *ngFor="let servicio of servicios">
      <a routerLink="/servicio/{{ servicio.nombre.toLowerCase() }}">
        <img class="card-img-top" src="assets/img/servicios/{{servicio.nombre}}.jpg" alt="{{servicio.alt}}" />
        <h2>{{ servicio.titulo }}</h2>
        <p>
          {{ servicio.textoCorto }}
        </p>
      </a>
    </div>
  </div>
</div>
