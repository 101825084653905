import { TARIFAS } from './../models/tarifas';
import { ACTIVIDADES } from './../models/listaActividades';
import { Actividad } from './../models/actividad';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: `root`
})
export class ActividadesService {

  constructor() {}

  getActividades(): Actividad[] {
    return ACTIVIDADES;
  }

  getTarifas(): any[] {
    return TARIFAS;
  }

  getActividad(id: number): Actividad {
    for (const element of ACTIVIDADES) {
      if (element.id === id) {
        return element;
      }
    }
  }

  getActividadByNombre(nombre: string): Actividad {
    for (const element of ACTIVIDADES) {
      if (element.nombre.toLowerCase() === nombre.toLowerCase()) {
        return element;
      }
    }
  }

}
