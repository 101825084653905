import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Actividad } from './../../models/actividad';
import { ActividadesService } from './../actividades.service';
import { Component, OnInit, Input } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-actividad-general',
  templateUrl: './actividad-general.component.html',
  styleUrls: ['./actividad-general.component.scss']
})

export class ActividadGeneralComponent implements OnInit {

  @Input() actividad: Actividad;

  constructor(
    private route: ActivatedRoute,
    private actividadesService: ActividadesService,
    private location: Location,
    private meta: Meta, private titleService: Title) { }

  ngOnInit() {
    this.getActividad();
    this.meta.addTag({name: `description`, content: this.actividad.metaDescripcion});
    this.meta.addTag({name: `keywords`, content: this.actividad.metaKeywords});
    // meta.addTag({name: `viewport`, content: `width=device-width, initial-scale=1`});
    this.meta.addTag({property: `og:title`, content: this.actividad.metaTitulo});
    this.meta.addTag({property: `og:type`, content: `website`});
    this.titleService.setTitle(this.actividad.metaTitulo);
  }

  getActividad(): void {
    const nombre = this.route.snapshot.paramMap.get('nombre');
    this.actividad = this.actividadesService.getActividadByNombre(nombre);
  }

  goBack(): void {
    this.location.back();
  }
}
