import { Actividad } from './actividad';

export const ACTIVIDADES: Actividad[] = [
  {
    id: 1,
    nombre: 'ASESORIA_AMBIENTAL',
    titulo: 'Asesoría Ambiental',
    texto: '<p>La empresa Juan Lo Arregla por medio de la línea de negocio Mano derecha, busca comprometerse con el medio ambiente, concientizando y enseñando a la comunidad como aportar con el cuidado del medio ambiente, por lo cual ofrecemos un servicio de capacitación a los hogares con el cual podrán disminuir el impacto ambiental que genera las actividades diarias.</p><p>Nuestro objetivo es por medio de nuestro personal enseñar a nuestros clientes al manejo de residuos y a controlar el consumo de servicios.</p><br><h3>Que incluye</h3><p>Para empezar con una asesoría ambiental se realizará una inspección de las redes hidráulicas y eléctricas con el objetivo de saber si hay presencia de daños, hay presencia de fugas o cortos y si es necesario alguna reforma.</p>',
    estado: 'string',
    urlImg: 'string',
    icono: 'asesoria_ambiental',
    metaTitulo: 'Asesoría ambiental para hogares y empresas | Juan lo Arregla',
    metaDescripcion: 'Juan lo Arregla te enseña a manejar de manera efectiva los residuos y a controlar el consumo de servicios. Conoce nuestro servicio de Asesoría ambiental.',
    alt: 'Asesoría ambiental Juan lo Arregla',
    metaKeywords: 'Cerrajeros a domiclio, remodelaciones para el hogar, plomeria a domicilio'
  },
  {
    id: 2,
    nombre: 'ASESORIA_SEGURIDAD',
    titulo: 'Asesoría Seguridad',
    texto: '<p>Realizar capacitación con la cual los clientes tendrán conocimiento de los riesgos a los cuales están expuestos en los hogares y como deben actuar en situaciones de emergencia.</p><h3>Objetivo:</h3><p>Disminuir los índices de accidentalidad en los hogares como lo son incendios, lesiones, cuidado en la niñez y adulto mayor.</p><h3>Que incluye</h3><h4>El protocolo de seguridad</h4><p>Se inicia con la inspección del inmueble, para poder realizar un listado de recomendaciones que de  que se deben tener de acuerdo a los riesgos analizados; adicional a esto se presentará un informe de cómo se debe actuar en situaciones de emergencia como lo son catástrofes naturales.</p><h4>Cuidado de bebes y adulto mayor</h4><p>Entendiendo que los bebes y el adulto mayor se encuentran en un alto riesgo de accidentalidad, ofrecemos el servicio para asesorar a los familiares o personas a cargo, para saber los cuidados pertinentes que se deben tener en los diferentes espacios del hogar.</p>',
    estado: 'string',
    urlImg: 'string',
    icono: 'asesoria_seguridad',
    metaTitulo: 'Asesoría de seguridad para hogar y empresas | Juan lo Arregla',
    metaDescripcion: 'Baja índices de accidentalidad en el hogar: incendios, lesiones, cuidado de la niñez y adulto mayor. Conoce ña asesoría de seguridad de Juan lo Arregla.',
    alt: 'Asesoría de seguridad para el hogar',
    metaKeywords: 'Cerrajeros a domiclio, remodelaciones para el hogar, plomeria a domicilio'
  },
  {
    id: 3,
    nombre: 'PLOMERIA',
    titulo: 'Plomeria',
    texto: '<p>Nuestros plomeros están para atender en el arreglo, instalación y mantenimiento de la red hidráulica. Este servicio es solicitado cuando se presentan problemas de filtraciones, humedades, taponamientos , daños sanitarios, entre otras actividades.</p><p>Selecciona una o varias de las actividades en nuestra lista; o solicite una cotización.</p>',
    estado: 'string',
    urlImg: 'string',
    icono: 'plomeria',
    metaTitulo: 'Plomería a domicilio en Bogotá | Juan lo Arregla',
    metaDescripcion: 'Plomería a domicilio en Bogotá. Reparamos fugas, tuberías, alcantarillados con plomeros certificados para solucionar tus problemas del hogar. Contáctanos.',
    alt: 'Plomería domicilio Bogotá',
    metaKeywords: 'Cerrajeros a domiclio, remodelaciones para el hogar, plomeria a domicilio'
  },
  {
    id: 4,
    nombre: 'REFORMAS',
    titulo: 'Reformas',
    texto: '<p>Juan Lo Arregla, pone a dispoción asesorías y nuestro equipo técnico, para la realización de actividades de remodelación , como lo són, distrbución de espacios, remodelaciones y acabados.</p><p>Agenda una visita de nuestros técnicos, y te realizaremos una cotización de acuerdo a tus requerimientos.</p>',
    estado: 'string',
    urlImg: 'string',
    icono: 'reformas',
    metaTitulo: 'Reformas y remodelación de hogares | Juan lo Arregla',
    metaDescripcion: 'Asesorías para la realización de actividades de reformas y remodelación de hogares, como distribución de espacios, remodelaciones y acabados. Contáctanos.',
    alt: 'Remodelacion de hogar Juan Lo Arregla',
    metaKeywords: 'Cerrajeros a domiclio, remodelaciones para el hogar, plomeria a domicilio'
  },
  {
    id: 5,
    nombre: 'CERRAJERIA',
    titulo: 'Cerrajería',
    texto: '<p>Nuestros cerrajeros esta a disposición para atender a los clientes cuando requieres la apertura de puertas, cambio de cerraduras o reparación.</p><p>Selecciona una o varias de las actividades en nuestra lista; o solicite una cotización.</p>',
    estado: 'string',
    urlImg: 'string',
    icono: 'cerrajeria',
    metaTitulo: 'Cerrajería a domicilio en Bogotá | Juan lo Arregla',
    metaDescripcion: 'Servicio de cerrajería en Bogotá. Cerrajeros profesionales para la apertura de puertas, cambio de cerraduras o reparación. Contáctanos.',
    alt: 'Cerrajería a domicilio',
    metaKeywords: 'Cerrajeros a domiclio, remodelaciones para el hogar, plomeria a domicilio'
  },
  {
    id: 6,
    nombre: 'ELECTRICIDAD',
    titulo: 'Electricidad',
    texto: '<p>Nuestros electricistas son dedicados a la instalación, mantenimiento y reparación de la de la red eléctrica con voltajes de 110 a 120; la cual esta compuesta de cableado, iluminación, accesorios electrónicos como tomas de corriente e interruptores.</p><p>Selecciona una o varias de las actividades en nuestra lista; o solicite una cotización.</p>',
    estado: 'string',
    urlImg: 'string',
    icono: 'electricidad',
    metaTitulo: 'Electricistas a domicilio en Bogotá | Juan lo Arregla',
    metaDescripcion: 'Servicio de electricistas a domicilio en Bogotá. Electricistas profesionales. Instalación, mantenimiento y reparación de redes eléctricas. Contáctanos.',
    alt: 'Electricistas a domicilio',
    metaKeywords: 'Cerrajeros a domiclio, remodelaciones para el hogar, plomeria a domicilio'
  },
  {
    id: 7,
    nombre: 'INSTALACION',
    titulo: 'Instalación',
    texto: '<p>Servicio direccionado al apoyo para la instalación de diferentes elementos en el hogar. Busca ayudar a los clientes al realizar cambios decorativos o  soporte para mudanzas .</p><p>Selecciona una o varias de las actividades en nuestra lista; o solicite una cotización.</p><b>Nota</b><p>Si los elementos a instalar tiene una dimensión mayor a 2 m2 favor informar en el momento de la solicitud del servicio.</p>',
    estado: 'string',
    urlImg: 'string',
    icono: 'instalacion',
    metaTitulo: 'Instaladores a domicilio en Bogotá | Juan lo Arregla',
    metaDescripcion: 'Servicio de instaladores a domicilio en Bogotá. Instalador profesional para diferentes elementos en el hogar. Cambios decorativos o soporte para mudanzas.',
    alt: 'Instaladores a domicilio',
    metaKeywords: 'Cerrajeros a domiclio, remodelaciones para el hogar, plomeria a domicilio'
  },
  {
    id: 8,
    nombre: 'JARDINERIA',
    titulo: 'Jardineria',
    texto: '<p>En juan lo arregla ofrecemos el servicio de cuidado y mantenimiento en jardines para hogares, esto incluye.</p><ul><li>cortar plantas.</li><li>Retirar maleza</li><li>abonar tierra</li><li>Fumigación</li></ul><ul><li>Turno $ 80.000</li></ul><h3>no incluye</h3><p><b>Recomendación:</b> realizar estas actividades una vez por mes</p>',
    estado: 'string',
    urlImg: 'string',
    icono: 'jardineria',
    metaTitulo: 'Jardineros a domicilio en Bogotá | Juan lo Arregla',
    metaDescripcion: 'Jardineros a domicilio en Bogotá. Cuidado y mantenimiento de jardines: cortar plantas, retirar maleza, abonar tierra, fumigación. Contáctanos.',
    alt: 'Jardineros a domicilio en Bogotá',
    metaKeywords: 'Cerrajeros a domiclio, remodelaciones para el hogar, plomeria a domicilio'
  },
  {
    id: 9,
    nombre: 'LIMPIEZA',
    titulo: 'Limpieza',
    texto: '<h3>Que incluye:</h3></br><p><b>Área social:</b>  barrer, trapear, aspirar,  limpieza de polvo en muebles, objetos decorativos, televisores; limpieza de paredes y puertas, lámparas, botar basura</p><p><b>Habitaciones:</b> Servicio de lavandería: lavado de ropa, planchado ( 8 prendas por hora).</p><p><b>Cocina:</b> barrer, trapear, limpieza de superficies, mesones, muebles, desengrasado general, limpieza de campanas extractoras, horno, lavado secado y guardado de loza, limpieza de nevera.</p><p><b>Baños:</b> desinfección general, limpieza de superficies, sanitarios, ducha, espejo, botar basura.</p><br><h3>No incluye:</h3><p>Materiales, suministro de maquinarias especializadas, Lavado de muebles, desmanchado de tapetes, tratamiento de pisos en madera, cocinar, trabajos a realizar en alturas arriba de 1.50,  domicilios,  paseo mascotas, acompañamiento a lugares externos del inmueble, jardinería.</p>',
    estado: 'string',
    urlImg: 'string',
    icono: 'limpieza',
    metaTitulo: 'Servicio de aseo para casas a domicilio | Juan lo Arregla',
    metaDescripcion: 'Juan lo Arregla te ofrece servicio de aseo para casas a domicilio en Bogotá. Conoce nuestro plan de aseo para el hogar y contrata profesionales en aseo.',
    alt: 'Servicio de aseo y limpieza',
    metaKeywords: 'Cerrajeros a domiclio, remodelaciones para el hogar, plomeria a domicilio'
  },
  {
    id: 10,
    nombre: 'PINTURA',
    titulo: 'Pintura',
    texto: '<h3>Que incluye:</h3></br><p>Servicio de resane y aplicación de pintura en muros y techos por deterioro.</p><br><h3>No incluye:</h3><p> Arreglo de fisuras por daños estructurales, no garantiza superficies afectadas por humedades, corrosión u otros daños de profundidad.</p>',
    estado: 'string',
    urlImg: 'string',
    icono: 'pintura',
    metaTitulo: 'Servicio de pintura profesional a domicilio | Juan lo Arregla',
    metaDescripcion: 'Servicio de pintura profesional a domicilio en Bogotá. Pintores profesionales de interiores y pintura de exteriores. Contáctanos ahora.',
    alt: 'Servicio de pintura a domicilio',
    metaKeywords: 'Cerrajeros a domiclio, remodelaciones para el hogar, plomeria a domicilio'
  },
];
