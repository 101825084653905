import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-boton-inscripcion',
  templateUrl: './boton-inscripcion.component.html',
  styleUrls: ['./boton-inscripcion.component.scss']
})
export class BotonInscripcionComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
