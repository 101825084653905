import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MailService } from 'src/app/formularios/mail.service';
import { AlertaConfirmacionComponent } from '../alerta-confirmacion/alerta-confirmacion.component';

@Component({
  selector: 'app-confirmacion',
  templateUrl: './confirmacion.component.html',
  styleUrls: ['./confirmacion.component.sass']
})
export class ConfirmacionComponent implements OnInit {

  actividades = [];
  valorTotal = 0;
  infoUsuario;
  aux;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private mailService: MailService,
    private dialog: MatDialog) {
    this.aux = data;
  }


  ngOnInit() {
    this.actividades = this.aux.actividades;
    this.infoUsuario = this.aux.usuario;
    this.getTotal();
  }

  getTotal() {
    this.actividades.forEach(act => {
      this.valorTotal += act.valor * act.cantidad;
    });
  }

  sendMail() {
    this.enviarActividadesCotizadas(this.actividades);
  }

  enviarActividadesCotizadas(laLista: any[]) {
    this.mailService.sendArray(laLista, this.infoUsuario.email, '', this.infoUsuario.nombre, this.infoUsuario.telefono
      , this.infoUsuario.fecha, this.valorTotal, this.infoUsuario.ciudad).subscribe(data => {
        if (data.codigo === 1) {
          const dialogo = this.dialog.open(AlertaConfirmacionComponent, {
            data:
            {
              mensaje: `<h4>${this.infoUsuario.nombre},</h4>
              <p>Ahora tu servicio esta en manos de Juan lo Arregla.</p>
              <p>En breve uno de nuestros asesores se contactará contigo.</p>`
              , titulo: 'Gracias por contactarnos'
              , labelConfirmacion: 'Confirmar'
            }
          });
          dialogo.componentInstance.okEmiter.subscribe(respuesta => {
            dialogo.close();
          });
        } else {
          alert('No se pudo enviar');
        }
      });
  }
}
