<div class="row">
  <div class="line col-sm-3"></div>
  <h3 class="col-sm-6 text-center titulo-cotizador">Cotiza aquí!</h3>
  <div class="line col-sm-3"></div>
</div>
<form [formGroup]="cotizacionForm" (ngSubmit)="onSubmit()">
  <div class="form-group">
    <label>Ciudad</label>
    <select class="form-control" formControlName="ciudad">
      <option value="Bogota D.C.">Bogota D.C.</option>
      <option value="Villavicencio">Villavicencio</option>
  </select>
  </div>
  <div class="form-group">
    <label>Correo</label>
    <input type="text" formControlName="email" class="form-control"
      [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
    <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
      <div *ngIf="f.email.errors.required">Este campo es requerido</div>
      <div *ngIf="f.email.errors.email">
        el correo debe ser un correo valido
      </div>
    </div>
  </div>
  <div class="form-group">
    <label>Nombre</label>
    <input type="text" formControlName="nombre" class="form-control"
      [ngClass]="{ 'is-invalid': submitted && f.nombre.errors }" />
    <div *ngIf="submitted && f.nombre.errors" class="invalid-feedback">
      <div *ngIf="f.nombre.errors.required">Este campo es requerido</div>
    </div>
  </div>
  <div class="form-group">
    <label>Teléfono</label>
    <input type="number" class="form-control" formControlName="telefono" />
  </div>
  <div class="form-group">
    <label>jornada</label>
    <select class="form-control" formControlName="jornada" [ngClass]="{ 'is-invalid': submitted && f.jornada.errors }">
      <option>4 horas</option>
      <option>8 horas</option>
    </select>
    <div *ngIf="submitted && f.jornada.errors" class="invalid-feedback">
      <div *ngIf="f.jornada.errors.required">
        Debes seleccionar una jornada
      </div>
    </div>
  </div>
  <div class="form-group">
    <label>fecha</label>
    <input type="date" formControlName="fecha" class="form-control"
      [ngClass]="{ 'is-invalid': submitted && f.fecha.errors }" />
    <div *ngIf="submitted && f.fecha.errors" class="invalid-feedback">
      <div *ngIf="f.fecha.errors.required">
        Debes seleccionar una jornada
      </div>
    </div>
  </div>
  <div class="form-check terminos">
    <input type="checkbox" formControlName="habeas" class="form-check-input"
      [ngClass]="{ 'is-invalid': submitted && f.habeas.errors }" />
    <label class="form-check-label" (click)=verHabeas()>
      Acepto <span> términos y condiciones</span>
    </label>
    <div *ngIf="submitted && f.habeas.errors" class="invalid-feedback">
      <div *ngIf="f.habeas.errors.required">debe aceptar los términos</div>
    </div>
  </div>
  <input type="hidden" formControlName="servicio" class="form-control" />
  <div class="w-100 d-none d-md-block"></div>
  <div class="form-group">
    <button class="btn btn-danger">Reservar</button>
  </div>
</form>
