import { Meta, Title } from '@angular/platform-browser';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActividadesService } from '../../actividades/actividades.service';
import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ConfirmacionComponent } from 'src/app/modal/confirmacion/confirmacion.component';
import { HabeasDataComponent } from 'src/app/modal/habeas-data/habeas-data.component';
import { AlertaConfirmacionComponent } from 'src/app/modal/alerta-confirmacion/alerta-confirmacion.component';

@Component({
  selector: 'app-cotiza-actividad',
  templateUrl: './cotiza-actividad.component.html',
  styleUrls: ['./cotiza-actividad.component.scss']
})
export class CotizaActividadComponent implements OnInit, AfterViewInit {

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  lasTarifas: any[];
  actividadesCotizadas = [];
  tipoServicio = `por Actividad`;
  cotizacionForm: FormGroup;
  submitted = false;
  cotizacion = 0;
  displayedColumns: string[] = [
    // `id`,
    // `actividad`,
    `subactividad`,
    `unidad`,
    `valor`,
    `acciones`
  ];

  dataSource = new MatTableDataSource();
  constructor(
    private actividadesService: ActividadesService,
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private meta: Meta, private titleService: Title) {
      meta.addTag({name: `description`, content: `Cotiza con nosotros ya mismo servicios de instalación, plomería, electricistas, jardinería a domicilio en Bogotá. Servicio profesional garantizado.`});
      meta.addTag({name: `keywords`, content: `Cerrajeros a domiclio, remodelaciones para el hogar, plomeria a domicilio`});
      // meta.addTag({name: `viewport`, content: `width=device-width, initial-scale=1`});
      meta.addTag({property: `og:title`, content: `Cotizar actividades de arreglos locativos | Juan lo Arregla`});
      meta.addTag({property: `og:type`, content: `website`}); }
  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  ngOnInit() {
    this.titleService.setTitle( 'Cotizar actividades de arreglos locativos | Juan lo Arregla' );
    this.lasTarifas = this.actividadesService.getTarifas();
    this.dataSource = new MatTableDataSource(this.lasTarifas);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.cotizacionForm = this.formBuilder.group({
      nombre: ['', Validators.required],
      telefono: [''],
      email: ['', [Validators.required, Validators.email]],
      servicio: [this.tipoServicio],
      jornada: [''],
      fecha: ['', Validators.required],
      cotizacion: [''],
      habeas: ['', Validators.required],
      ciudad: ['Bogota D.C.', Validators.required]
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.cotizacionForm.controls; }



  sumarActividad(tarifa: any) {
    tarifa.cantidad += 1;
    this.actividadesCotizadas = this.lasTarifas.filter(r => r.cantidad > 0);
  }
  restarActividad(tarifa: any) {
    if (tarifa.cantidad > 0) {
      tarifa.cantidad -= 1;
      this.actividadesCotizadas = this.lasTarifas.filter(r => r.cantidad > 0);
    }
  }

  validarActividadesCotizadas() {
    this.submitted = true;
    this.cotizacion = 0;
    const dataUsuario = {
      email: this.cotizacionForm.controls[`email`].value,
      nombre: this.cotizacionForm.controls[`nombre`].value,
      telefono: this.cotizacionForm.controls[`telefono`].value,
      servicio: this.cotizacionForm.controls[`servicio`].value,
      fecha: this.cotizacionForm.controls[`fecha`].value,
      habeas: this.cotizacionForm.controls[`habeas`].value,
      ciudad: this.cotizacionForm.controls[`ciudad`].value
    };
    this.actividadesCotizadas = this.lasTarifas.filter(r => r.cantidad > 0);

    if (this.cotizacionForm.invalid || this.actividadesCotizadas.length <= 0) {
      const dialogo = this.dialog.open(AlertaConfirmacionComponent,
        {
          data:
          {
            mensaje: `debes seleccionar al menos una actividad`
            , titulo: 'termina de cotizar'
            , labelConfirmacion: 'volver'
          }
        });
      dialogo.componentInstance.okEmiter.subscribe(respuesta => {
        dialogo.close();
      });
      return;
    }
    this.actividadesCotizadas.forEach(actividad => {
      this.cotizacion += (actividad.cantidad * actividad.valor);
    });
    this.dialog.open(ConfirmacionComponent, { width: `100rem`, data: { actividades: this.actividadesCotizadas, usuario: dataUsuario } });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  verHabeas() {
    this.dialog.open(HabeasDataComponent, { width: `60rem` });
  }
  resetForm() {
    console.log(`pendiente rest`);
  }
}
