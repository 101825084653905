import { Component, OnInit, Inject, EventEmitter } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-alerta-confirmacion',
  templateUrl: './alerta-confirmacion.component.html',
  styleUrls: ['./alerta-confirmacion.component.scss']
})
export class AlertaConfirmacionComponent implements OnInit {

  aux;
  titulo = 'Titulo confirmación';
  mensaje = 'Mensaje confirmación';
  labelConfirmacion = 'Aceptar';
  labelCancelar;
  okEmiter = new EventEmitter();
  cancelEmiter = new EventEmitter();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.aux = data;
  }

  ngOnInit() {
    this.titulo = this.aux.titulo;
    this.mensaje = this.aux.mensaje;
    this.labelConfirmacion = this.aux.labelConfirmacion;
    this.labelCancelar = this.aux.labelCancelar;
  }

  ok() {
    this.okEmiter.emit();
  }

  cancel() {
    this.cancelEmiter.emit();
  }
}
