import { Actividad } from './../../models/actividad';
import { ActividadesService } from './../actividades.service';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-botones-actividades',
  templateUrl: './botones-actividades.component.html',
  styleUrls: ['./botones-actividades.component.scss']
})
export class BotonesActividadesComponent implements OnInit {

  @Input()
  botonesPequenos: boolean;
  actividades: Actividad[];

  constructor(private actividadesService: ActividadesService) { }

  ngOnInit() {
    this.getActividades();
  }

  private getActividades(): void {
    this.actividades = this.actividadesService.getActividades();
  }

}
