<h4 mat-dialog-title>Resumen Cotización</h4>
<div mat-dialog-content>
  <div class="row">
    <div class="col-md-12 text-center" style="margin-bottom: 15px">
      Hola <strong>{{infoUsuario.nombre | uppercase}}</strong>, antes de enviar la cotización te recordamos que quieres
      solicitar:
    </div>
    <div class="offset-md-1 col-10">
      <table class="table table-hover">
        <thead>
          <tr>
            <th>Actividad</th>
            <th>Unidad</th>
            <th>Valor</th>
            <th>Cantidad</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let actividad of actividades">
            <td>{{actividad.subactividad}}</td>
            <td>{{actividad.unidad}}</td>
            <td>{{actividad.valor}}</td>
            <td>{{actividad.cantidad}}</td>
            <td>{{(actividad.valor * actividad.cantidad )}}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="col-md-12">
      <div class="float-right">
        <strong>Total: </strong> ${{valorTotal}} *
      </div>
      * Valor estimado sujeto a verificación
    </div>
  </div>
  <br>
</div>
<div mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancelar</button>
  <button mat-button (click)="sendMail()" class="btn-danger">Enviar</button>
</div>
