import { Meta, Title } from '@angular/platform-browser';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nosotros',
  templateUrl: './nosotros.component.html',
  styleUrls: ['./nosotros.component.scss']
})
export class NosotrosComponent implements OnInit {

  constructor(private meta: Meta, private titleService: Title) {
    meta.addTag({name: `description`, content: `Empresa de servicios domiciliarios de arreglos locativos. Electricistas, `
    || `plomeros, pintores, jardineros y aseadores en Bogotá. Conócenos.`});
    meta.addTag({name: `keywords`, content: `Cerrajeros a domiclio, remodelaciones para el hogar, plomeria a domicilio`});
    // meta.addTag({name: `viewport`, content: `width=device-width, initial-scale=1`});
    meta.addTag({property: `og:title`, content: `Empresa de arreglos locativos | Juan lo Arregla`});
    meta.addTag({property: `og:type`, content: `website`});
  }

  ngOnInit() {
    this.titleService.setTitle( 'Empresa de arreglos locativos | Juan lo Arregla' );
  }

}
