<form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
  <div class="form-group">
    <label>Ciudad</label>
    <select class="form-control" formControlName="ciudad">
      <option value="Bogota D.C.">Bogota D.C.</option>
      <option value="Villavicencio">Villavicencio</option>
  </select>
  </div>
  <div class="form-group">
    <label>Correo</label>
    <input type="text" formControlName="email" class="form-control"
      [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
    <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
      <div *ngIf="f.email.errors.required">Este campo es requerido</div>
      <div *ngIf="f.email.errors.email">
        el correo debe ser un correo valido
      </div>
    </div>
  </div>
  <div class="form-group">
    <label>Nombre</label>
    <input type="text" formControlName="nombre" class="form-control"
      [ngClass]="{ 'is-invalid': submitted && f.nombre.errors }" />
    <div *ngIf="submitted && f.nombre.errors" class="invalid-feedback">
      <div *ngIf="f.nombre.errors.required">Este campo es requerido</div>
    </div>
  </div>
  <div class="form-group">
    <label>Teléfono</label>
    <input type="number" class="form-control" formControlName="telefono" />
  </div>
  <div class="form-group">
    <label>Descripción del problema:</label>
    <textarea formControlName="cotizacion" class="form-control" rows="3"
      [ngClass]="{ 'is-invalid': submitted && f.cotizacion.errors }"></textarea>
    <div *ngIf="submitted && f.cotizacion.errors" class="invalid-feedback">
      <div *ngIf="f.cotizacion.errors.required">
        Debes ingresar un detalle de la cotización para poder enviarla
      </div>
    </div>
  </div>
  <!-- <div class="custom-file">
    <label class="custom-file-label" for="customFileLang" #labelImport>Adjuntar foto</label>
    <input type="file" class="custom-file-input" id="customFileLang" lang="es" formControlName="Foto1"
      (change)="onFileChangeF($event)" />
  </div> -->
  <!-- <div class="form-group">
    <label>Foto 2</label>
    <input type="file" class="form-control" formControlName="Foto2" (change)="onFileChangeF($event)" />
  </div>
  <div class="form-group">
    <label>Foto 3</label>
    <input type="file" class="form-control" formControlName="Foto3" (change)="onFileChangeF($event)" />
  </div>
  <div class="form-group">
    <label>Foto 4</label>
    <input type="file" class="form-control" formControlName="Foto4" (change)="onFileChangeF($event)" />
  </div> -->
  <div class="form-check terminos">
    <input type="checkbox" formControlName="habeas" class="form-check-input"
      [ngClass]="{ 'is-invalid': submitted && f.habeas.errors }" />
    <label class="form-check-label" (click)=verHabeas()>
      Acepto <span> términos y condiciones</span>
    </label>
    <div *ngIf="submitted && f.habeas.errors" class="invalid-feedback">
      <div *ngIf="f.habeas.errors.required">debe aceptar los términos</div>
    </div>
  </div>
  <input type="hidden" formControlName="servicio" class="form-control" />
  <div class="form-group">
    <button class="btn btn-danger">Enviar</button>
  </div>
</form>
