import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './general/header/header.component';
import { FooterComponent } from './general/footer/footer.component';
import { HomeComponent } from './general/home/home.component';
import { NosotrosComponent } from './general/nosotros/nosotros.component';
import { ActividadesComponent } from './actividades/actividades/actividades.component';
import { ServiciosComponent } from './servicios/servicios/servicios.component';
import { CotizaDiasComponent } from './servicios/cotiza-dias/cotiza-dias.component';
import { CotizaActividadComponent } from './formularios/cotiza-actividad/cotiza-actividad.component';
import { InscripcionComponent } from './servicios/inscripcion/inscripcion.component';
import { ActividadGeneralComponent } from './actividades/actividad-general/actividad-general.component';
import { BotonInscripcionComponent } from './general/boton-inscripcion/boton-inscripcion.component';
import { BotonesServiciosComponent } from './servicios/botones-servicios/botones-servicios.component';
import { BotonesActividadesComponent } from './actividades/botones-actividades/botones-actividades.component';
import { ServicioGeneralComponent } from './servicios/servicio-general/servicio-general.component';
import { ContactoComponent } from './formularios/contacto/contacto.component';
import { PinturaComponent } from './actividades/pintura/pintura.component';
import { LimpiezaComponent } from './actividades/limpieza/limpieza.component';
import { CotizadorDiasComponent } from './formularios/cotizador-dias/cotizador-dias.component';
import { CotizadorJornadaComponent } from './formularios/cotizador-jornada/cotizador-jornada.component';
import { ConfirmacionComponent } from './modal/confirmacion/confirmacion.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HabeasDataComponent } from './modal/habeas-data/habeas-data.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { AlertaConfirmacionComponent } from './modal/alerta-confirmacion/alerta-confirmacion.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    NosotrosComponent,
    ActividadesComponent,
    ServiciosComponent,
    CotizaDiasComponent,
    CotizaActividadComponent,
    InscripcionComponent,
    ActividadGeneralComponent,
    BotonInscripcionComponent,
    BotonesServiciosComponent,
    BotonesActividadesComponent,
    ServicioGeneralComponent,
    ContactoComponent,
    PinturaComponent,
    LimpiezaComponent,
    CotizadorDiasComponent,
    CotizadorJornadaComponent,
    ConfirmacionComponent,
    HabeasDataComponent,
    AlertaConfirmacionComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatDialogModule,
    MatButtonModule,
    MatTableModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [Title],
  bootstrap: [AppComponent],
  entryComponents: [
    ConfirmacionComponent,
    AlertaConfirmacionComponent,
    HabeasDataComponent
  ]
})
export class AppModule { }
