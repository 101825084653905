import { MailService } from './../mail.service';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Component, OnInit, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { HabeasDataComponent } from 'src/app/modal/habeas-data/habeas-data.component';
import { DialogService } from 'src/app/modal/dialog.service';
import { DomSanitizer, Meta, Title } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { AlertaConfirmacionComponent } from 'src/app/modal/alerta-confirmacion/alerta-confirmacion.component';

@Component({
  selector: 'app-contacto',
  templateUrl: './contacto.component.html',
  styleUrls: ['./contacto.component.scss']
})
export class ContactoComponent implements OnInit {

  @ViewChild('labelImport', { static: true })
  labelImport: ElementRef;

  registerForm: FormGroup;
  submitted = false;
  nombreArchivo: string;
  imgBase64;
  archivo;


  constructor(
    private formBuilder: FormBuilder,
    private mailService: MailService,
    private sanitizer: DomSanitizer,
    private dialog: MatDialog,
    private meta: Meta, private titleService: Title) {
      meta.addTag({name: `description`, content: `Cuéntanos qué servicio profesional de arreglos locativos necesitas y Juan lo Arregla te ayuda. Servicio a domicilio en toda Bogotá. Cotiza ahora.`});
      meta.addTag({name: `keywords`, content: `Cerrajeros a domiclio, remodelaciones para el hogar, plomeria a domicilio`});
      // meta.addTag({name: `viewport`, content: `width=device-width, initial-scale=1`});
      meta.addTag({property: `og:title`, content: `Cotización personalizada de actividades | Juan lo Arregla`});
      meta.addTag({property: `og:type`, content: `website`});
    }

  ngOnInit() {
    this.titleService.setTitle( 'Cotización personalizada de actividades | Juan lo Arregla' );
    this.registerForm = this.formBuilder.group({
      nombre:     ['', Validators.required],
      telefono:   [''],
      cotizacion: ['', Validators.required],
      servicio:   ['Contacto'],
      email:      ['', [Validators.required, Validators.email]],
      Foto1:      [''],
      habeas:     ['', Validators.required],
      ciudad:     ['Bogota D.C.', Validators.required]
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.registerForm.controls; }

  onSubmit() {
    this.submitted = true;
    const formData = new FormData();
    if (this.registerForm.invalid) {
      return;
    }

    formData.append('nombre', this.registerForm.controls[`nombre`].value);
    formData.append('telefono', this.registerForm.controls[`telefono`].value);
    formData.append('cotizacion', this.registerForm.controls[`cotizacion`].value);
    formData.append('servicio', this.registerForm.controls[`servicio`].value);
    formData.append('email', this.registerForm.controls[`email`].value);
    formData.append('ciudad', this.registerForm.controls[`ciudad`].value);
    formData.append('Foto1', this.archivo);

    this.mailService.send(formData).subscribe(data => {
      if (data.codigo === 1) {
        const dialogo = this.dialog.open(AlertaConfirmacionComponent,
          {
          data:
            {
              mensaje: `<h4>${this.registerForm.controls[`nombre`].value},</h4>
              <p>Ahora tu servicio esta en manos de Juan lo Arregla.</p>
              <p>En breve uno de nuestros asesores se contactará contigo.</p>`
              , titulo: 'Gracias por contactarnos'
              , labelConfirmacion: 'Confirmar'
            }
          }
        );
        dialogo.componentInstance.okEmiter.subscribe(respuesta => {
          dialogo.close();
          this.resetForm();
        });
      } else {
        alert(`No se pudo enviar`);
      }
    });
  }

  onFileChangeF(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.labelImport.nativeElement.innerText = event.target.files[0].name;
      this.archivo = file;
      // multi file in one: https://medium.com/@coco.boudard/bootstrap-custom-file-input-with-angular-9039681cd025
    }
  }

  resetForm() {
    this.registerForm.reset();
    this.submitted = false;
    this.labelImport.nativeElement.innerText = 'Adjuntar foto';
    this.archivo = null;
  }


  seleccionarArchivo(event: any) {
    event.preventDefault();
    const fileHiden = document.getElementById('fileHiden') as HTMLElement;
    fileHiden.click();
  }

  transform() {
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.imgBase64);
  }

  onFileChange(event: any) {
    const reader = new FileReader();
    if (event.target && event.target.files.length > 0) {
      const file = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = (() => {
        const value = reader.result;
        this.nombreArchivo = file.name;
        this.imgBase64 = value;
      });
    }
  }

  verHabeas() {
    this.dialog.open(HabeasDataComponent, {width: `60rem`});
  }
}
