<div class="container actividades">
  <div class="row d-flex justify-content-around">
    <div class="col text-center" [ngClass]="{ 'col-sm-3 ' : !botonesPequenos }"  *ngFor="let actividad of actividades">
      <a routerLink="/actividad/{{ actividad.nombre.toLowerCase( ) }}">
        <img src="assets/img/icons/{{(botonesPequenos? '':'lg_') + actividad.icono}}.png" alt="{{ actividad.titulo }}"/>
        <div  [ngClass]="{
          'opcion' : !botonesPequenos,
          'small-font' : botonesPequenos
        }">{{ actividad.titulo }}
        </div>
      </a>
    </div>
  </div>
</div>
