<div class="text-center">
  <div class="row justify-content-md-center mb-3">
    <a class="btn btn-danger col col-md-4" target="_self" href="assets/docs/Mano_Derecha_Juan_Lo_Arregla.pdf"
      download="Mano_Derecha_Juan_Lo_Arregla.pdf">Mas informaci&oacute;n</a>
  </div>
  <h2>Inscripci&oacute;n Anual</h2>
  <div class="row justify-content-around mt-3">
    <div class="col-md-4 mb-1">
      <a class="btn btn-danger col mb-1" target="_self" href="assets/docs/Mano_Derecha_Paquete_Basico.pdf"
        download="Mano_Derecha_Paquete_Basico.pdf">B&aacute;sico</a>
      <table class="table table-sm">
        <thead>
          <tr>
            <th scope="col">Actividades</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Instalaciones</td>
          </tr>
          <tr>
            <td>Electricista</td>
          </tr>
          <tr>
            <td>Plomero</td>
          </tr>
          <tr>
            <td>Pintor</td>
          </tr>
          <tr>
            <td>Cerrajero</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="col-md-4 mb-1">
      <a class="btn btn-danger col mb-1" target="_self" href="assets/docs/Mano_Derecha_Paquete_Completo.pdf"
        download="Mano_Derecha_Paquete_Completo.pdf">Completo</a>
      <table class="table table-sm">
        <thead>
          <tr>
            <th scope="col">Actividades</th>
          </tr>
        </thead>
        <tbody>
          <tr class="table-success">
            <td>Limpieza</td>
          </tr>
          <tr class="table-success">
            <td>jardiner&iacute;a</td>
          </tr>
          <tr>
            <td>Instalaciones</td>
          </tr>
          <tr>
            <td>Electricista</td>
          </tr>
          <tr>
            <td>Plomero</td>
          </tr>
          <tr>
            <td>Pintor</td>
          </tr>
          <tr>
            <td>Cerrajero</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
