import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, Input } from '@angular/core';
import { MailService } from '../mail.service';
import { HabeasDataComponent } from 'src/app/modal/habeas-data/habeas-data.component';
import { MatDialog } from '@angular/material/dialog';
import { AlertaConfirmacionComponent } from 'src/app/modal/alerta-confirmacion/alerta-confirmacion.component';

@Component({
  selector: 'app-cotizador-jornada',
  templateUrl: './cotizador-jornada.component.html',
  styleUrls: ['./cotizador-jornada.component.scss']
})
export class CotizadorJornadaComponent implements OnInit {

  @Input()
  tipoServicio: string;
  cotizacionForm: FormGroup;
  submitted = false;

  constructor(
    private formBuilder: FormBuilder,
    private mailService: MailService,
    private dialog: MatDialog
    ) { }

  ngOnInit() {
    this.cotizacionForm = this.formBuilder.group({
      nombre:     ['', Validators.required],
      telefono:   [''],
      email:      ['', [Validators.required, Validators.email]],
      servicio:   [this.tipoServicio],
      jornada:    ['', Validators.required],
      fecha:      ['', Validators.required],
      habeas:     ['', Validators.required],
      ciudad: ['Bogota D.C.', Validators.required]
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.cotizacionForm.controls; }

  onSubmit() {
      this.submitted = true;

      // stop here if form is invalid
      if (this.cotizacionForm.invalid) {
          return;
      }

      this.mailService.send(this.cotizacionForm.value).subscribe(data => {
        if (data.codigo === 1) {
          const dialogo = this.dialog.open(AlertaConfirmacionComponent,
            {
            data:
              {
                mensaje: `<h4>${this.cotizacionForm.controls[`nombre`].value},</h4>
                <p>Ahora tu servicio esta en manos de Juan lo Arregla.</p>
                <p>En breve uno de nuestros asesores se contactará contigo.</p>`
                , titulo: 'Gracias por contactarnos'
                , labelConfirmacion: 'Confirmar'
              }
            }
          );
          dialogo.componentInstance.okEmiter.subscribe(respuesta => {
            dialogo.close();
            this.resetForm();
          });
        } else {
          alert(`No se pudo enviar`);
        }
      });

  }
  resetForm() {
   this.cotizacionForm.reset();
   this.submitted = false;
  }

  verHabeas() {
    this.dialog.open(HabeasDataComponent, {width: `60rem`});
  }
}
