import { SERVICIOS } from 'src/app/models/listaServicios';
import { Servicio } from './../models/servicio';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ServiciosService {

  constructor() { }

  getServicios(): Servicio[] {
    return SERVICIOS;
  }

  getServicio(id: number): Servicio {
    for (const element of SERVICIOS) {
      if (element.id === id) {
        return element;
      }
    }
  }

  getServicioByNombre(nombre: string): Servicio {
    for (const element of SERVICIOS) {
      if (element.nombre.toLowerCase() === nombre.toLowerCase()) {
        return element;
      }
    }
  }

}
