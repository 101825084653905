export const TARIFAS: any[] = [
   {
    id: '1',
    actividad: 'INSTALACIONES',
    subactividad: 'Instalación de repisas decorativas',
    unidad: 'M',
    valor: 27000,
    cantidad: 0
  },
 {
    id: '2',
    actividad: 'INSTALACIONES',
    subactividad: 'Instalación de cortinas y blackouts',
    unidad: 'M',
    valor: 30000,
    cantidad: 0
  },
 {
    id: '3',
    actividad: 'INSTALACIONES',
    subactividad: 'Instalación de cuadros',
    unidad: 'Und',
    valor: 15000,
    cantidad: 0
  },
 {
    id: '4',
    actividad: 'INSTALACIONES',
    subactividad: 'Instalación de Tendederos',
    unidad: 'Und',
    valor: 40000,
    cantidad: 0
  },
 {
    id: '5',
    actividad: 'INSTALACIONES',
    subactividad: 'Instalación de espejos ',
    unidad: 'M2',
    valor: 25000,
    cantidad: 0
  },
 {
    id: '6',
    actividad: 'INSTALACIONES',
    subactividad: 'instalación de puerta + marco',
    unidad: 'Und',
    valor: 140000,
    cantidad: 0
  },
 {
    id: '7',
    actividad: 'INSTALACIONES',
    subactividad: 'Instalación de puerta en madera',
    unidad: 'Und',
    valor: 50000,
    cantidad: 0
  },
 {
    id: '8',
    actividad: 'INSTALACIONES',
    subactividad: 'Instalación de puertas en vidrio',
    unidad: 'Und',
    valor: 150000,
    cantidad: 0
  },
 {
    id: '9',
    actividad: 'INSTALACIONES',
    subactividad: 'Instalación de estufa de gas',
    unidad: 'Und',
    valor: 40000,
    cantidad: 0
  },
 {
    id: '10',
    actividad: 'INSTALACIONES',
    subactividad: 'Instalación de salpicadero',
    unidad: 'Ml',
    valor: 25000,
    cantidad: 0
  },
 {
    id: '11',
    actividad: 'INSTALACIONES',
    subactividad: 'Instalación de soportes para tv  a  pared',
    unidad: 'Hasta 42',
    valor: 40000,
    cantidad: 0
  },
 {
    id: '12',
    actividad: 'INSTALACIONES',
    subactividad: 'Instalación de soportes para tv  a  pared',
    unidad: 'Más de 42',
    valor: 60000,
    cantidad: 0
  },
 {
    id: '13',
    actividad: 'INSTALACIONES',
    subactividad: 'Instalación Canaleta para cables televisión',
    unidad: '1ml',
    valor: 7000,
    cantidad: 0
  },
 {
    id: '14',
    actividad: 'INSTALACIONES',
    subactividad: 'Instalación de lamparas (paneles led, de inscrustar, de sobreponer, apliques',
    unidad: 'Und',
    valor: 15000,
    cantidad: 0
  },
 {
    id: '15',
    actividad: 'INSTALACIONES',
    subactividad: 'Armado e muebles modulares ',
    unidad: 'M3',
    valor: 50000,
    cantidad: 0
  },
 {
    id: '16',
    actividad: 'ELECTRICIDAD',
    subactividad: 'Cambio de balas o roseta',
    unidad: 'Und',
    valor: 10000,
    cantidad: 0
  },
 {
    id: '17',
    actividad: 'ELECTRICIDAD',
    subactividad: 'Bombillas alogenas',
    unidad: 'Und',
    valor: 5000,
    cantidad: 0
  },
 {
    id: '18',
    actividad: 'ELECTRICIDAD',
    subactividad: 'Cambio de interruptores y tomacorriente',
    unidad: 'Und',
    valor: 10000,
    cantidad: 0
  },
 {
    id: '19',
    actividad: 'ELECTRICIDAD',
    subactividad: 'Arreglo de estufa',
    unidad: 'Und',
    valor: 40000,
    cantidad: 0
  },
 {
    id: '20',
    actividad: 'ELECTRICIDAD',
    subactividad: 'instalación punto eléctrico',
    unidad: 'Und',
    valor: 40000,
    cantidad: 0
  },
 {
    id: '21',
    actividad: 'ELECTRICIDAD',
    subactividad: 'Instalacion de estufa eléctrica',
    unidad: 'Und',
    valor: 70000,
    cantidad: 0
  },
 {
    id: '22',
    actividad: 'ELECTRICIDAD',
    subactividad: 'Instalación de ducha eléctrica',
    unidad: 'Und',
    valor: 45000,
    cantidad: 0
  },
 {
    id: '23',
    actividad: 'ELECTRICIDAD',
    subactividad: 'Revisión Corto básico hasta 15 puntos',
    unidad: 'Und',
    valor: 50000,
    cantidad: 0
  },
 {
    id: '24',
    actividad: 'ELECTRICIDAD',
    subactividad: 'Cambio de breakers, monopolar totalizador',
    unidad: 'Und',
    valor: 30000,
    cantidad: 0
  },
 {
    id: '25',
    actividad: 'ELECTRICIDAD',
    subactividad: 'Cambio barraje',
    unidad: 'Und',
    valor: 50000,
    cantidad: 0
  },
 {
    id: '26',
    actividad: 'CERRAJERÍA ',
    subactividad: 'Apertura de puertas',
    unidad: 'Und',
    valor: 50000,
    cantidad: 0
  },
 {
    id: '27',
    actividad: 'CERRAJERÍA ',
    subactividad: 'Apertura de peurtas de seguridad',
    unidad: 'Und',
    valor: 70000,
    cantidad: 0
  },
 {
    id: '28',
    actividad: 'CERRAJERÍA ',
    subactividad: 'Cambio de guardas',
    unidad: 'Und',
    valor: 40000,
    cantidad: 0
  },
 {
    id: '29',
    actividad: 'CERRAJERÍA ',
    subactividad: 'Cambio de guardas de seguridad',
    unidad: 'Und',
    valor: 60000,
    cantidad: 0
  },
 {
    id: '30',
    actividad: 'PLOMERÍA',
    subactividad: 'instalación de acoples sanitarios, lavamanos y lavaplatos',
    unidad: 'Und',
    valor: 40000,
    cantidad: 0
  },
 {
    id: '31',
    actividad: 'PLOMERÍA',
    subactividad: 'instalación de valvula de corte',
    unidad: 'Und',
    valor: 50000,
    cantidad: 0
  },
 {
    id: '32',
    actividad: 'PLOMERÍA',
    subactividad: 'Calmbio de flotador del talque de agua',
    unidad: 'Und',
    valor: 30000,
    cantidad: 0
  },
 {
    id: '33',
    actividad: 'PLOMERÍA',
    subactividad: 'Limpieza de tanque de agua',
    unidad: 'Und',
    valor: 50000,
    cantidad: 0
  },
 {
    id: '34',
    actividad: 'PLOMERÍA',
    subactividad: 'Cambio de arbol de entrada',
    unidad: 'Und',
    valor: 20000,
    cantidad: 0
  },
 {
    id: '35',
    actividad: 'PLOMERÍA',
    subactividad: 'cambio de arbol de salida',
    unidad: 'Und',
    valor: 20000,
    cantidad: 0
  },
 {
    id: '36',
    actividad: 'PLOMERÍA',
    subactividad: 'cambio de kit sanitario ( entrada y salida)',
    unidad: 'Und',
    valor: 35000,
    cantidad: 0
  },
 {
    id: '37',
    actividad: 'PLOMERÍA',
    subactividad: 'Cambio de griferia',
    unidad: 'Und',
    valor: 30000,
    cantidad: 0
  },
 {
    id: '38',
    actividad: 'PLOMERÍA',
    subactividad: 'Desmonte instalación de sanitario',
    unidad: 'Und',
    valor: 80000,
    cantidad: 0
  },
 {
    id: '39',
    actividad: 'PLOMERÍA',
    subactividad: 'Desmonte de mueble integral ',
    unidad: 'Und',
    valor: 50000,
    cantidad: 0
  },
 {
    id: '40',
    actividad: 'PLOMERÍA',
    subactividad: 'Cambio de accesorios para baño',
    unidad: 'Und',
    valor: 50000,
    cantidad: 0
  },
 {
    id: '41',
    actividad: 'PLOMERÍA',
    subactividad: 'Cambio de tubería sanitaria',
    unidad: 'Ml',
    valor: 30000,
    cantidad: 0
  },
 {
    id: '42',
    actividad: 'PLOMERÍA',
    subactividad: 'Cambio Tubo de 1/2 - 3/4`` con regata por- 1 mt ',
    unidad: 'Ml',
    valor: 20000,
    cantidad: 0
  },
 {
    id: '43',
    actividad: 'PLOMERÍA',
    subactividad: 'Cambio de desagüe lavaplatos',
    unidad: 'Und',
    valor: 40000,
    cantidad: 0
  },
 {
    id: '44',
    actividad: 'PLOMERÍA',
    subactividad: 'Cambio de lavadero material lijero (ej plástico)',
    unidad: 'Und',
    valor: 60000,
    cantidad: 0
  },
 {
    id: '45',
    actividad: 'PLOMERÍA',
    subactividad: 'Cambio de llaves en lavadero, punto hidraulico para  lavadora',
    unidad: 'Und',
    valor: 20000,
    cantidad: 0
  },
 {
    id: '46',
    actividad: 'PLOMERÍA',
    subactividad: 'Cambio e instalacion de lavamanos o lavaplatos',
    unidad: 'Und',
    valor: 50000,
    cantidad: 0
  },
 {
    id: '47',
    actividad: 'PLOMERÍA',
    subactividad: 'Destape tuberia - sonda manual ( lavamanos- lavaplatos- sanitarios- sifon)',
    unidad: 'Und',
    valor: 50000,
    cantidad: 0
  },
 {
    id: '48',
    actividad: 'PLOMERÍA',
    subactividad: 'Destape tuberia - sonda Electrica  ( lavamanos- lavaplatos- sanitarios- sifon)',
    unidad: 'Und',
    valor: 100000,
    cantidad: 0
  },
 {
    id: '49',
    actividad: 'PLOMERÍA',
    subactividad: 'Cambio de mezclador de ducha ( exploracion, incluye resane)',
    unidad: 'Und',
    valor: 150000,
    cantidad: 0
  },
 {
    id: '50',
    actividad: 'PLOMERÍA',
    subactividad: 'Cambio baztago mezclador ducha',
    unidad: 'Und',
    valor: 30000,
    cantidad: 0
  },
 {
    id: '51',
    actividad: 'PLOMERÍA',
    subactividad: 'Exploración ( 1 x .40m ) + Reemplazo de tubería pvc 1 a 2 ',
    unidad: 'Und',
    valor: 160000,
    cantidad: 0
  },
 {
    id: '52',
    actividad: 'PLOMERÍA',
    subactividad: 'Exploración ( 1 x .40m )  + Reemplazo de tubería pvc 2 a 4 ',
    unidad: 'Und',
    valor: 170000,
    cantidad: 0
  },
 {
    id: '53',
    actividad: 'PLOMERÍA',
    subactividad: 'Exploración ( 1 x .40m ) + reemplazo tubería pvc 6',
    unidad: 'Und',
    valor: 180000,
    cantidad: 0
  },
 {
    id: '54',
    actividad: 'PLOMERÍA',
    subactividad: 'Reemplazo de tuvería en cobre 1/2 3/4',
    unidad: 'Ml',
    valor: 30000,
    cantidad: 0
  },
 {
    id: '55',
    actividad: 'PLOMERÍA',
    subactividad: 'Reemplazo tuvería en cobre 1',
    unidad: 'Ml',
    valor: 30000,
    cantidad: 0
  },
 {
    id: '56',
    actividad: 'PLOMERÍA',
    subactividad: 'Caja de aguas negras liempieza con hipoclorato ',
    unidad: 'M2',
    valor: 150000,
    cantidad: 0
  },
 {
    id: '57',
    actividad: 'TRASTEOS',
    subactividad: 'Trasteo de camas, poltronas, mesas, bibliotecas, sofas, lavadoras etc.',
    unidad: '6 Und',
    valor: 200000,
    cantidad: 0
  },
 {
    id: '58',
    actividad: 'TRASTEOS',
    subactividad: 'Embalaje por caja',
    unidad: 'und',
    valor: 20000,
    cantidad: 0
  },
 {
    id: '59',
    actividad: 'PINTURA',
    subactividad: 'Emboquillado',
    unidad: 'm2',
    valor: 7000,
    cantidad: 0
  },
 {
    id: '60',
    actividad: 'PINTURA',
    subactividad: 'impermeabilización ',
    unidad: 'M2',
    valor: 19000,
    cantidad: 0
  },
 {
    id: '61',
    actividad: 'PINTURA',
    subactividad: 'Pintura muros Vinilo- 3 manos',
    unidad: 'M2',
    valor: 5000,
    cantidad: 0
  },
 {
    id: '62',
    actividad: 'PINTURA',
    subactividad: 'Pintura muros Vinilo- 2 manos',
    unidad: 'M2',
    valor: 4000,
    cantidad: 0
  },
 {
    id: '63',
    actividad: 'PINTURA',
    subactividad: 'Pintura techo Vinilo- 3 manos',
    unidad: 'M2',
    valor: 6000,
    cantidad: 0
  },
 {
    id: '64',
    actividad: 'PINTURA',
    subactividad: 'Pintura techo Vinilo- 2 manos',
    unidad: 'M2',
    valor: 4500,
    cantidad: 0
  },
 {
    id: '65',
    actividad: 'PINTURA',
    subactividad: 'Pintura acorazado ',
    unidad: 'M2',
    valor: 10000,
    cantidad: 0
  },
 {
    id: '66',
    actividad: 'PINTURA',
    subactividad: 'Pintura epoxica para pisos',
    unidad: 'M2',
    valor: 16000,
    cantidad: 0
  },
 {
    id: '67',
    actividad: 'PINTURA',
    subactividad: 'Señalización y demarcación de pisos',
    unidad: 'Ml',
    valor: 14000,
    cantidad: 0
  },
 {
    id: '68',
    actividad: 'PINTURA',
    subactividad: 'Pintura antihongos',
    unidad: 'M2',
    valor: 8000,
    cantidad: 0
  },
 {
    id: '69',
    actividad: 'PINTURA',
    subactividad: 'Resane de grietas',
    unidad: 'Ml',
    valor: 6000,
    cantidad: 0
  },
 {
    id: '70',
    actividad: 'MAMPOSTERÍA',
    subactividad: 'Instalacion Enchape ceramica- Baldosin- Azulejo (Pared y pisos)',
    unidad: 'M2',
    valor: 20000,
    cantidad: 0
  },
 {
    id: '71',
    actividad: 'MAMPOSTERÍA',
    subactividad: 'Instalacion Enchape ceramica- Baldosin- Azulejo ( techos)',
    unidad: 'M2',
    valor: 25000,
    cantidad: 0
  },
 {
    id: '72',
    actividad: 'MAMPOSTERÍA',
    subactividad: 'Instalacion Enchape Porcelanato',
    unidad: 'M2',
    valor: 30000,
    cantidad: 0
  },
 {
    id: '73',
    actividad: 'MAMPOSTERÍA',
    subactividad: 'Demoliciones muros de ladrillos ( con recoleccion de escombros en sitio)',
    unidad: 'M3',
    valor: 65000,
    cantidad: 0
  },
 {
    id: '74',
    actividad: 'MAMPOSTERÍA',
    subactividad: 'Demoliciones Pisos ( con recoleccion de escombros en sitio)',
    unidad: 'M2',
    valor: 15000,
    cantidad: 0
  },
 {
    id: '75',
    actividad: 'MAMPOSTERÍA',
    subactividad: 'Pañete liso Pared',
    unidad: 'M2',
    valor: 10000,
    cantidad: 0
  },
 {
    id: '76',
    actividad: 'MAMPOSTERÍA',
    subactividad: 'Pañete liso techo',
    unidad: 'M2',
    valor: 12000,
    cantidad: 0
  },
 {
    id: '77',
    actividad: 'MAMPOSTERÍA',
    subactividad: 'Manposteria en Bloque ó ladrillo',
    unidad: 'M2',
    valor: 10000,
    cantidad: 0
  },
 {
    id: '78',
    actividad: 'MAMPOSTERÍA',
    subactividad: 'Mesones en concreto fundido en sitio',
    unidad: 'M2',
    valor: 70000,
    cantidad: 0
  },
 {
    id: '79',
    actividad: 'MAMPOSTERÍA',
    subactividad: 'Pisos en granito fundido y pulido',
    unidad: 'M2',
    valor: 70000,
    cantidad: 0
  },
 {
    id: '80',
    actividad: 'TECHOS',
    subactividad: 'Cambio de tejas eternit ( hasta 5 metros de altura)',
    unidad: 'Und',
    valor: 40000,
    cantidad: 0
  },
 {
    id: '81',
    actividad: 'TECHOS',
    subactividad: 'Cambio de tejas plásticas ( hasta 5 metros de altura)',
    unidad: 'Und',
    valor: 40000,
    cantidad: 0
  },
 {
    id: '82',
    actividad: 'TECHOS',
    subactividad: 'Cambio de caballetes ( hasta 5 metros de altura)',
    unidad: 'Und',
    valor: 40000,
    cantidad: 0
  },
 {
    id: '83',
    actividad: 'TECHOS',
    subactividad: 'Revisión de Goteras',
    unidad: 'glb',
    valor: 27000,
    cantidad: 0
  },
 {
    id: '84',
    actividad: 'MANTENIMIENTO',
    subactividad: 'Limpieza de canales ',
    unidad: 'Ml',
    valor: 3500,
    cantidad: 0
  },
 {
    id: '85',
    actividad: 'MANTENIMIENTO',
    subactividad: 'Reemplazo de canales',
    unidad: 'Ml',
    valor: 8000,
    cantidad: 0
  },
 {
    id: '86',
    actividad: 'MANTENIMIENTO',
    subactividad: 'Desmonte de canales',
    unidad: 'Ml',
    valor: 12500,
    cantidad: 0
  },
 {
    id: '87',
    actividad: 'MANTENIMIENTO',
    subactividad: 'Limpieza de bajantes',
    unidad: 'Ml',
    valor: 4000,
    cantidad: 0
  },
 {
    id: '88',
    actividad: 'MANTENIMIENTO',
    subactividad: 'Sondeo de bajantes',
    unidad: 'Und',
    valor: 60000,
    cantidad: 0
  },
 {
    id: '89',
    actividad: 'MANTENIMIENTO',
    subactividad: 'Limpieza de cubierta',
    unidad: 'Und',
    valor: 3000,
    cantidad: 0
  },
 {
    id: '90',
    actividad: 'MANTENIMIENTO',
    subactividad: 'Reinstalación de tejas (hasta 5 m de altura)',
    unidad: 'Und',
    valor: 40000,
    cantidad: 0
  },
 {
    id: '91',
    actividad: 'MANTENIMIENTO',
    subactividad: 'Cambio de tejas',
    unidad: 'Und',
    valor: 60000,
    cantidad: 0
  },
 {
    id: '92',
    actividad: 'MANTENIMIENTO',
    subactividad: 'Mantenimiento de cerchas metalicas ( hasta 5 m de altura)',
    unidad: 'Und',
    valor: 12000,
    cantidad: 0
  },

];
