import { ActivatedRoute } from '@angular/router';
import { ServiciosService } from './../servicios.service';
import { Component, OnInit, Input } from '@angular/core';
import { Servicio } from 'src/app/models/servicio';


@Component({
  selector: 'app-servicio-general',
  templateUrl: './servicio-general.component.html',
  styleUrls: ['./servicio-general.component.scss']
})
export class ServicioGeneralComponent implements OnInit {

  @Input()  servicio: Servicio;

  constructor(
    private route: ActivatedRoute,
    private serviciosService: ServiciosService) { }

  ngOnInit() {
    this.getServicio();
  }

  getServicio() {
    const nombre = this.route.snapshot.paramMap.get('nombre');
    this.servicio = this.serviciosService.getServicioByNombre(nombre);
  }
}
