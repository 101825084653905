<div class="txt-contenido">
  <h5 mat-dialog-title>HABEAS DATA</h5>
  <div mat-dialog-content>
    <h4>AVISO DE PRIVACIDAD</h4>
    <p>
      El presente Aviso de Privacidad establece los términos y condiciones en virtud de
      los cuales SOSTENIBILIDAD SAS realizará el tratamiento de sus datos personales de
      acuerdo a con los dispuestos en la Ley 1581 de 2012 y sus DECRETOS REGLAMENTARIOS.
    </p>
    <p>
      El tratamiento de sus datos se realizara con lo siguientes fines:
    </p>
    <p>
      Notificaciones sobre la prestación del servicio, envió de publicidad y promociones.
    </p>
    <p>
      Los datos personales serán tratados con el grado de protección adecuado,
      tomándose las medidas de seguridad necesarias para evitar su alteración, pérdida,
      tratamiento o acceso no autorizado por parte de terceros.
    </p>
  </div>
  <div mat-dialog-actions align='end'>
    <button mat-raised-button (click)="dialogRef.close()">Volver</button>
  </div>
</div>
