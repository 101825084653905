<div class="container row min-fullscreen align-items-center">
  <div class="col-lg-6 d-flex d-flex align-items-center">
    <div class="embed-responsive embed-responsive-4by3">
      <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/NkWf_BHQa6U"></iframe>
    </div>
  </div>
  <div class="col-lg-6 txt-contenido">
    <h1>Quienes somos</h1>
    <p>
      Juan Lo arregla es una empresa dedicada a la prestación de servicios
      domiciliarios que busca, a través de su personal formalizado y capacitado,
      destacarse en el mercado por un servicio confiable, ágil y de calidad.
    </p>
    <h2>Misión</h2>
    <p>
      Facilitar la vida a nuestros clientes, ofreciendo un servicio integrado
      para el soporte de diferentes actividades en el hogar, en su empresa o
      donde lo necesite.
    </p>
    <h2>Visión</h2>
    <p>
      Ser la empresa líder en la prestación de servicios domiciliarios y así
      poder llegar a todos los lugares donde tengan la necesidad de contratar
      nuestros servicios.
    </p>
  </div>
</div>
