import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  constructor(private meta: Meta, private titleService: Title) {
    meta.addTag({name: `description`, content: `Profesionales en cerrajería a domicilio, plomería en Bogotá, remodelaciones `
                                            || `de hogar y arreglos locativos empresariales. Conoce ya Juan lo Arregla.`});
    meta.addTag({name: `keywords`, content: `Cerrajeros a domiclio, remodelaciones para el hogar, plomeria a domicilio`});
    // meta.addTag({name: `viewport`, content: `width=device-width, initial-scale=1`});
    meta.addTag({property: `og:title`, content: `Cerrajería, plomería, remodelaciones y acabados | Juan Lo Arregla`});
    meta.addTag({property: `og:type`, content: `website`});
  }

  ngOnInit() {
    this.titleService.setTitle( 'Cerrajería, plomería, remodelaciones y acabados | Juan Lo Arregla' );
  }
}
