import { MailService } from './../../formularios/mail.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Actividad } from './../../models/actividad';
import { ActividadesService } from './../actividades.service';
import { Component, OnInit } from '@angular/core';
import { HabeasDataComponent } from 'src/app/modal/habeas-data/habeas-data.component';
import { MatDialog } from '@angular/material/dialog';
import { AlertaConfirmacionComponent } from 'src/app/modal/alerta-confirmacion/alerta-confirmacion.component';


@Component({
  selector: `app-pintura`,
  templateUrl: `./pintura.component.html`,
  styleUrls: [`./pintura.component.scss`]
})

export class PinturaComponent implements OnInit {

  actividad: Actividad;
  cotizacionForm: FormGroup;
  submitted: boolean;

  tipo: string;

  constructor(
    private formBuilder: FormBuilder,
    private actividadesService: ActividadesService,
    private mailService: MailService,
    private dialog: MatDialog) { }

  ngOnInit() {
    this.getActividad();
    this.initForm();
  }

  getActividad(): void {
    const id = 10;
    this.actividad = this.actividadesService.getActividad(id);
  }

  initForm() {
    this.cotizacionForm = this.formBuilder.group({
      nombre: [``, Validators.required],
      telefono: [``],
      email: [``, [Validators.required, Validators.email]],
      servicio: [`Pintura`],
      tipo: [``, Validators.required],
      metros: [``],
      amoblada: [`Si`],
      cantidad: [``],
      altura: [`No`],
      cotizacion: [``],
      habeas: [``, Validators.required]
    });
  }
  // convenience getter for easy access to form fields
  get f() { return this.cotizacionForm.controls; }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.cotizacionForm.invalid) {
      return;
    }
    const tipo = this.cotizacionForm.controls[`tipo`].value;
    let totalCotizacion = 0;
    if (tipo === `mi casa`) {
      const metros: number = this.cotizacionForm.controls[`metros`].value;
      const amoblada = this.cotizacionForm.controls[`amoblada`].value;
      totalCotizacion = metros * 3 * (amoblada === `si` ? 8000 : 7000);
      this.cotizacionForm.controls[`altura`].disable();
      this.cotizacionForm.controls[`cantidad`].disable();
    } else {
      const metros: number = this.cotizacionForm.controls[`metros`].value;
      const cantidad: number = this.cotizacionForm.controls[`cantidad`].value;
      const altura = this.cotizacionForm.controls[`altura`].value;
      totalCotizacion = metros * cantidad * (altura === `si` ? 8000 : 7000);
      this.cotizacionForm.controls[`amoblada`].disable();
    }
    this.cotizacionForm.controls[`cotizacion`].setValue(totalCotizacion);

    console.log(this.cotizacionForm.controls[`cotizacion`].value);
    this.mailService.send(this.cotizacionForm.value).subscribe(data => {
      if (data.codigo === 1) {
        const dialogo = this.dialog.open(AlertaConfirmacionComponent,
          {
          data:
            {
              mensaje: `<h4>${this.cotizacionForm.controls[`nombre`].value},</h4>
              <p>Ahora tu servicio esta en manos de Juan lo Arregla.</p>
              <p>En breve uno de nuestros asesores se contactará contigo.</p>`
              , titulo: 'Gracias por contactarnos'
              , labelConfirmacion: 'Confirmar'
            }
          }
        );
        dialogo.componentInstance.okEmiter.subscribe(respuesta => {
          dialogo.close();
          this.resetForm();
        });
      } else {
        alert(`No se pudo enviar`);
      }
    });

  }
  resetForm() {
    this.cotizacionForm.reset();
    this.submitted = false;
    this.initForm();
  }
  verHabeas() {
    this.dialog.open(HabeasDataComponent, { width: `60rem` });
  }
}
